.intro {
  background-color: #e7e7e5;
  padding: 145px 135px;

  @media (max-width: 1200px) {
    padding: 50px;
  }

  @media (max-width: 900px) {
    display: block;
  }

  @media (max-width: 360px) {
    padding: 50px 20px;
  }

  .heading-primary {
    margin-bottom: 50px;

    @media (max-width: 1200px) {
      margin-bottom: 30px;
    }
  }

  &__grid {
    display: flex;

    @media (max-width: 900px) {
      display: block;
    }
  }

  &__img-wrapper {
    flex: 0 0 55%;

    img {
      width: 100%;
    }

    @media (max-width: 900px) {
      margin-bottom: 40px;
    }
  }

  &__text-wrapper {
    flex: 1;
    padding-left: 125px;
    max-width: 605px;

    .paragraph:not(:last-child) {
      margin-bottom: 25px;
    }

    @media (max-width: 1200px) {
      padding-left: 60px;
      font-size: 14px;
    }

    @media (max-width: 900px) {
      padding-left: 0;
      max-width: initial;
    }
  }

  &__title {
    text-transform: uppercase;
    color: #4f5252;
    font-weight: 600;
  }
}

.container {
  max-width: 1405px;
  margin: 0 auto;
}
