.cards {
  padding: 115px;
  background-color: #bcbebe;

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 25px;

    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 560px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 50px;
    }
  }

  @media (max-width: 786px) {
    padding: 50px;
  }

  @media (max-width: 360px) {
    padding: 50px 20px;
  }

  img {
    max-width: 100%;
    height: auto;

    @media (max-width: 1024px) {
      width: 100%;
    }
  }

  &__title {
    display: block;
    margin: 30px 0 20px;
    font-weight: 600;

    @media (max-width: 540px) {
      margin: 20px 0 10px;
    }
  }
}
