@import './assets/styles/reset';
@import './assets/styles/utils';

@import './assets/styles/nav';
@import './assets/styles/hamburger';
@import './assets/styles/header';
@import './assets/styles/intro';
@import './assets/styles/features';
@import './assets/styles/cards';
@import './assets/styles/form';
@import './assets/styles/faq';
@import './assets/styles/disclaimer';
@import './assets/styles/footer';

*,
*::before,
*::after {
  box-sizing: border-box;
  outline: none;
}

html {
  scroll-behavior: smooth;
}

body {
  color: #131718;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.6;
  font-size: 14px;
}

.page {
  padding-top: 114px;

  @media (max-width: 768px) {
    padding-top: 97px;
  }
}

.logo {
  max-width: 100px;
  height: 46px;
}
