.disclaimer {
  text-align: center;
  background-color: #f0eff1;
  padding: 105px;

  @media (max-width: 900px) {
    padding: 50px;
  }

  @media (max-width: 360px) {
    padding: 50px 20px;
  }

  .heading-primary {
    margin-bottom: 50px;

    @media (max-width: 900px) {
      margin-bottom: 30px;
    }
  }

  .paragraph {
    max-width: 875px;
    margin: 0 auto;

    &:nth-of-type(1) {
      margin-bottom: 20px;
    }
  }
}