.form {
  padding: 100px 50px;
  text-align: center;
  background-color: #f1f0ef;
  position: relative;

  @media (max-width: 786px) {
    padding: 50px;
  }

  @media (max-width: 360px) {
    padding: 50px 20px;
  }

  .heading-primary {
    margin-bottom: 20px;
  }

  .paragraph:nth-of-type(1) {
    margin-bottom: 20px;
  }

  &__wrapper {
    max-width: 555px;
    margin: 0 auto;
  }

  &__form {
    margin-top: 80px;
    background-color: #fff;
    padding: 35px 25px;
    text-align: left;

    @media (max-width: 1200px) {
      margin-top: 30px;
    }
  }

  &__title {
    font-size: 20px;
    color: #7c7f81;
    margin-bottom: 10px;
  }

  input,
  select {
    padding: 12px 15px;
    background-color: transparent;
    border: 2px solid #bcbebe;
    width: 100%;
    color: #bbbdbd;
  }

  input {
    display: block;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &::placeholder {
      color: #bbbdbc;
    }
  }

  select {
    background-color: transparent;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  &__select-wrapper {
    position: relative;
    max-width: 340px;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      pointer-events: none;
    }

    &::after {
      height: calc(100% - 12px);
      width: 45px;
      right: 2px;
      top: 6px;
      background-color: #fff;
      pointer-events: none;
    }

    &::before {
      position: absolute;
      border: 1px solid #bcbebe;
      border-top: 0;
      border-right: 0;
      width: 15px;
      height: 15px;
      transform: rotate(-45deg) translateY(-100%);
      top: 50%;
      right: 10px;
      z-index: 2;
    }
  }

  hr {
    border: 1px solid #bcbebe;
    color: #bcbebe;
    margin: 45px 0;
  }

  &__btn {
    background-color: #ae4a43;
    color: #fff;
    padding: 15px 40px;
    margin-top: 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  &__img1,
  &__img2 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: 1440px) {
      max-width: 300px;
    }

    @media (max-width: 1100px) {
      display: none;
    }
  }

  &__img1 {
    left: 0;
  }

  &__img2 {
    right: 0;
  }

  &__message {
    p {
      padding: 10px;
      border-radius: 5px;
      margin-top: 30px;
      background-color: #fffab5;
    }
  }
}
