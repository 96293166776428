.faq {
  padding: 110px 135px;

  @media (max-width: 900px) {
    padding: 50px;
  }

  @media (max-width: 360px) {
    padding: 50px 20px;
  }

  .heading-primary {
    text-align: center;
    margin-bottom: 50px;
  }

  .paragraph:not(:last-child) {
    margin-bottom: 40px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 60px;
    max-width: 950px;
    margin: 0 auto;

    @media (max-width: 1200px) {
      display: block;

      .paragraph {
        margin-bottom: 40px;
      }
    }
  }

  &__question {
    margin-bottom: 20px;
    display: block;
    font-weight: 600;
  }
}