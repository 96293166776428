.heading-primary {
  font-size: 32px;
  font-weight: 700;
}

.paragraph {
  color: #4f5252;

  &--bold {
    font-weight: 600;
  }
}

.flex {
  display: flex;
}