.footer {
  margin-top: 100px;
  padding: 50px 105px;
  background-image: linear-gradient(to bottom, #fff, #d4d6d6);
  border-top: 1px solid #a8aaab;

  @media (max-width: 1024px) {
    color: red;
  }

  @media (max-width: 900px) {
    padding: 50px;
    margin-top: 50px;

    &__logo-wrapper {
      text-align: center;
    }
  }

  @media (max-width: 600px) {
    &__list {
      display: block;
    }
  }

  .flex {
    flex-wrap: wrap;

    .paragraph {
      width: 100%;
    }

    @media (max-width: 900px) {
      justify-content: center;
      text-align: center;

      .paragraph,
      .footer__list {
        flex: 0 0 100%;
      }

      .paragraph {
        margin-bottom: 20px;
      }
    }
  }

  img {
    margin-bottom: 20px;
  }

  &__list {
    margin-left: auto;
  }

  &__list-item {
    &:not(:last-child) {
      margin-right: 40px;

      @media (max-width: 1200px) {
        margin-right: 20px;
      }

      @media (max-width: 600px) {
        margin-right: 0;
        margin-bottom: 5px;
      }
    }

    a,
    a:visited {
      color: #4f5252;
      text-decoration: none;
    }
  }
}
