.header {
  padding: 150px 200px;
  background-color: #f15a5a;
  position: relative;
  overflow: hidden;

  @media (max-width: 960px) {
    padding: 150px 100px;
  }

  @media (max-width: 700px) {
    padding: 150px 50px;
  }

  @media (max-width: 500px) {
    padding: 75px 20px;

    .heading-primary {
      font-size: 28px;
    }

    .header__subheading {
      font-size: 16px;
    }
  }

  &__slide-1,
  &__slide-2 {
    background-size: cover;
    background-position: 57%;
    background-repeat: no-repeat;
    animation-name: slider;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__slide-1 {
    background-image: url('./assets/img/bg-hero.jpg');
  }

  &__slide-2 {
    background-image: url('./assets/img/bg-hero-2.jpeg');
    animation-delay: 5s;
  }

  &__banner {
    max-width: 800px;
    margin: 0 auto;
    z-index: 2;
    position: relative;
  }

  .heading-primary {
    line-height: 1.1;
    margin-bottom: 10px;
  }

  &__subheading {
    font-size: 20px;
    line-height: 1.2;
  }

  &__left {
    position: absolute;
    left: 40px;
    z-index: 1;
    color: #dc7777;
    font-size: 116px;
    top: 50%;
    transform: translateY(-50%);
    line-height: 0.9;
    text-transform: uppercase;

    @media (max-width: 960px) {
      display: none;
    }
  }
}

@keyframes slider {
  0% { opacity: 1; }
  10% { opacity: 1 }
  20% { opacity: 1 }
  30% { opacity: 1 }
  40% { opacity: 1 }
  50% { opacity: 0 }
  60% { opacity: 0 }
  70% { opacity: 0 }
  80% { opacity: 0 }
  90% { opacity: 0 }
  100% { opacity: 1 }
}
