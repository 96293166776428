.features {
  text-align: center;
  padding: 60px 15px;

  .heading-primary {
    margin-bottom: 10px;
  }

  .paragraph {
    margin-bottom: 80px;
  }

  img {
    margin-bottom: 25px;
    max-width: 75px;
    height: auto;
  }

  @media (max-width: 1024px) {
    padding: 50px;
  }

  @media (max-width: 360px) {
    padding: 50px 20px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 10px;
    max-width: 1140px;
    margin: 0 auto;

    @media (max-width: 1024px) {
      grid-template-columns: repeat(4, 1fr);
      gap: 40px 10px;
    }

    @media (max-width: 560px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__col {
    .paragraph {
      margin-bottom: 0;
    }
  }
}