.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background-color: #fff;
  border-bottom: 1px solid #a8aaab;

  &.is-active {
    .nav__list {
      height: 240px;
    }
  }

  &__topbar {
    display: flex;

    .logo {
      margin-bottom: 10px;
    }
  }

  &__wrapper {
    max-width: 1675px;
    padding: 20px 100px 15px;
    margin: 0 auto;
    transition: all .3s;

    @media (max-width: 768px) {
      padding: 20px 50px;
    }
  }

  &__list {
    display: flex;

    @media (max-width: 768px) {
      overflow: hidden;
      height: 0;
      transition: all 0.3s;
      flex-direction: column;
      align-items: center;
    }
  }

  &__list-item {
    margin-right: 35px;

    @media (max-width: 768px) {
      margin-right: 0;
      font-size: 18px;

      &:first-child {
        margin-top: 40px;
      }
    }
  }

  &__link,
  &:visited {
    color: #4f5252;
    text-decoration: none;
  }
}
